<template>
  <div class="home" v-if="qunData">
    <Image width="100" height="100" :src="qunData.avatar"></Image>
    <label class="name-label">{{qunData.name}}</label>
    <label class="title-label">{{qunData.title}}</label>
    <div class="line"></div>
    <div class="module-view">
      <div class="title">群成员</div>
      <div class="members-container">
        <div class="members" v-for="(item,index) in qunData.members" :key="index">
          <Image :src="item.avatar" class="avatar" fit="fill"></Image>
          <div class="nickname">{{item.nickname}}</div>
        </div>
      </div>
    </div>
    <div class="module-view">
      <div class="title">群简介</div>
      <div class="desp">{{qunData.intro}}</div>
    </div>
    <div class="module-view">
      <div class="title">群友秀</div>
      <Image @click="clickQunyouxiu" :src="qunData.qunyouxiu" class="qunyouxiu" fit="fill"></Image>
    </div>
    <div class="module-view">
      <Divider :style="{ color: '#666666', borderColor: '#666666', padding: '0px 16px'}">
        入群福利
      </Divider>
      <!-- <div class="title">入群福利</div> -->
      <div style="margin:10px 0" v-for="(item,index) in qunData.fuli" :key="index">
        <div class="fuli-title">{{item.title}}</div>
        <div class="fuli-intro">{{item.intro}}</div>
        <Image :src="item.image" class="fuli-image" fit="fill" v-if="item.image"></Image>
      </div>
    </div>
    <div class="module-view">
      <Divider :style="{ color: '#666666', borderColor: '#666666', padding: '0px 16px'}">
        常见问题
      </Divider>
      <!-- <div class="title">常见问题</div> -->
      <div style="margin:10px 0" v-for="(item,index) in qunData.question" :key="index">
        <div class="fuli-title">{{item.question}}</div>
        <div class="fuli-intro">{{item.answer}}</div>
      </div>
    </div>
    <div class="module-view">
      <Divider :style="{ color: '#666666', borderColor: '#666666', padding: '0px 16px'}">
        群友反馈
      </Divider>
      <!-- <div class="title">群友反馈</div> -->
      <div class="feedback" style="margin:10px 0" v-for="(item,index) in qunData.feedback" :key="index">
        <Image :src="item.avatar" class="avatar" fit="fill"></Image>
        <div class="comment-container">
          <div class="nickname">{{item.nickname}}</div>
          <div class="comment">{{item.comment}}</div>
        </div>
      </div>
    </div>
    <Sticky :offset-bottom="0" position="bottom">
    <div class="bottom-btns">
      <Button type="primary" @click="free" size="large" class="bottom-btn"
        color="linear-gradient(to right, #00CCCC, #009999)">{{qunData.left_btn}}</Button>
      <Button type="primary" @click="wxpay" class="bottom-btn" size="large"
        color="linear-gradient(to right, #ff6034, #ee0a24)">{{qunData.right_btn}}</Button>
    </div>
  </Sticky>
  </div>
  
  <Popup v-model:show="showMini" closeable>
    <div class="mini-title">长按识别下方二维码</div>
    <div class="mini-subtitle">进入小程序即可免费取图</div>
    <Image :src="qunData.minicode" class="mini-code" fit="fill"></Image>
  </Popup>
  <Popup v-model:show="showQun" closeable>
    <div class="code-title">专属VIP进群码</div>
    <div class="code-subtitle">复制下方进群码发给您的取图小助手即可进入VIP表情群</div>
    <!-- <div class="mini-subtitle">{{qunData.code}}</div> -->
    <div id="copyTarget" class="code-view">{{qunData.code}}</div>
    <Button style="margin:20px;width:50vw" type="primary" id="copyBtn" :data-clipboard-text="qunData.code"
      data-clipboard-action="copy" data-clipboard-target="#copyTarget" @click="copy">点击复制进群码</Button>
  </Popup>
</template>

<script>
// @ is an alias to /src
import { Button, Toast, Image, ImagePreview, Divider, Sticky, Popup, Dialog } from "vant";
import wx from "weixin-jsapi"
import Clipboard from "clipboard";


const appid = "wx21b7482ea3efa282";//公众号的唯一标识
const redirect_uri = "http://facepay.foryixin.com";//授权后重定向的回调链接地址
const scope = "snsapi_base";//非静默授权：snsapi_userinfo  静默授权：snsapi_base

export default {
  name: 'Home',
  components: {
    Button,
    Toast,
    Image,
    ImagePreview,
    Divider,
    Sticky,
    Popup,
    Dialog
  },
  data() {
    return {
      openid: '',
      qunData: '',
      showMini: false,
      showQun: false,
      qunCode: '',
      itemIsDisabled: false
    }
  },
  async created() {
    const openid = localStorage.getItem('OPENID')
    if (!(this.$route.query && this.$route.query.code) && !openid) {
      //获取code
      document.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${appid}#wechat_redirect`);
    } else if (this.$route.query && this.$route.query.code && !openid) {
      //请求后端接口获取用户的信息
      let result = await this.$api.get('authorize', { code: this.$route.query.code, scope: scope, appid: appid });
      //保存用户的openid
      localStorage.setItem('OPENID', result.openid)
    }
    var self = this;
    this.$api.get('/quninfo', {}).then(res => {
      self.qunData = res
    })

    //获取当前页面url
    console.log(this.$route.query)
    let url = location.href
    // 调用后端接口，给后台url，让后台返回appId、时间戳、随机串、签名，建议vue路由使用hash模式，可以根据#分隔路由
    this.$api.get('/payconfig', { url: url.split("#")[0], appid: appid, code: this.$route.query.code }).then(res => {
      console.log(res)
      wx.config({
        debug: false,//用于调试，这里一般在测试阶段先用true，等打包给后台的时候就改回false,
        appId: res.sign.appId,
        timestamp: res.sign.timestamp,
        nonceStr: res.sign.nonceStr,
        signature: res.sign.signature,
        jsApiList: ['chooseWXPay']//需要使用的API，此处只使用了微信支付接口
      })
      //查看配置是否成功，成功时候才能使用微信SDK
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ['chooseWXPay'],
          success: function (res) {
            console.log("success")
            console.log(res)
          },
          fail: function (res) {
            console.log("fail");
            console.log(res)
          }
        })
      })
    })
  },
  methods: {
    wxpay() {
      const openid = localStorage.getItem('OPENID')
      if (!openid) {
        Toast('获取信息失败');
        return
      }
      var self = this;
      this.$api.get('prepay', { openid: openid }).then(prepayRes => {
        wx.chooseWXPay({
          timestamp: prepayRes.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: prepayRes.nonceStr, // 支付签名随机串，不长于 32 位
          package: prepayRes.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: prepayRes.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: prepayRes.paySign, // 支付签名
          success: function (res) {
            // 支付成功后的回调函数
            self.$api.get('checkpay', { order_id: prepayRes.order_id }).then(checkRes => {
              if (checkRes.status == 2) {
                self.showQun = true;
              } else {
                Toast('支付失败');
              }
            })
          }
        });
      })
    },
    clickQunyouxiu() {
      ImagePreview([this.qunData.qunyouxiu]);
    },
    free() {
      this.showMini = true;
    },
    copy() {
      // 因为此事件不能添加.stop，所以要设置一个开关, 500ms后打开开关
      this.itemIsDisabled = true
      setTimeout(() => {
        this.itemIsDisabled = false
      }, 500)
      let clipboard = new Clipboard('#copyBtn');
      clipboard.on('success', e => {
        console.log(this, '复制成功!', 'success', e);
        Dialog.alert({
          title: '进群码已复制',
          message: '发给取图小助手即可进群哦，感谢您的支持',
          theme: 'round-button',
          confirmButtonText:'好的'
        }).then(() => {
          // on close
        });
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        console.log(this, '该浏览器不支持自动复制!', 'warning', e);
        clipboard.destroy()
      })
    },
  }
}
</script>

<style>
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: rgb(247, 247, 247);
  position: relative;
  overflow: hidden;
}

.name-label {
  font-weight: 500;
  font-size: 18px;
  color: #333333;
}

.title-label {
  font-size: 16px;
  color: #999999;
  margin-top: 4px;
  margin-bottom: 20px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #dddddd
}

.module-view {
  background-color: #fff;
  width: 100%;
  padding: 10px;
  position: relative;
}

.module-view .title {
  text-align: left;
  width: 100%;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #333333
}

.module-view .desp {
  text-align: left;
  width: calc(100% - 40px);
  margin: 0 20px;
  font-size: 18px;
  color: #999999;
  margin-top: 10px;
}

.members-container {
  width: calc(100% - 40px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
}

.members {
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  text-overflow: ellipisis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  position: relative;
}

.members .avatar {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  overflow: hidden;
}

.members .nickname {
  min-width: 0;
  text-overflow: ellipisis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  width: 55px;
  color: #999999;
  margin: 4px 0;
}

.qunyouxiu {
  width: calc(100% - 40px);
  margin: 10px 20px 0 20px;
  border-radius: 10px;
  overflow: hidden;
}

.fuli-title {
  text-align: left;
  width: 100%;
  margin-left: 20px;
  font-size: 18px;
  color: #336699;
}

.fuli-intro {
  font-size: 16px;
  color: #999999;
  text-align: left;
  width: calc(100% - 40px);
  margin: 6px 20px 20px 20px;
}

.feedback {
  display: flex;
  width: calc(100% - 40px);
  position: relative;
}

.feedback .avatar {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 20px;
}

.comment-container {
  display: flex;
  flex-direction: column;
}

.comment-container .nickname {
  text-align: left;
  width: 100%;
  margin-left: 10px;
  font-size: 16px;
  color: #999999;
}

.comment-container .comment {
  font-size: 16px;
  color: #333333;
  text-align: left;
  /* width: calc(100% - 40px); */
  margin: 2px 0px 10px 10px;
}

.bottom-btns {
  display: flex;
  align-items: center;
  width: 100vw;
}

.bottom-btn {
  width: 50vw;
  height: 80px;
  background-color: brown;
  position: relative;
}

.mini-view {
  width: 50vw;
  position: relative;
}

.mini-code {
  width: 60vw;
  margin: 10px 20px;
}

.mini-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  color: #336699;
}

.mini-subtitle {
  font-size: 16px;
}

.code-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  color: #336699;
}

.code-subtitle {
  font-size: 16px;
  width: 80vw;
  margin: 10px;
}

.code-view {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  color: darkcyan;
}
</style>
