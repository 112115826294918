// 引入axios
import axios from "axios";
// 设置请求基准地址
// axios.defaults.baseURL = "http://127.0.0.1:3020";
axios.defaults.baseURL = "https://facepay.funey.xyz";
// 导出封装方法

export default {
    get (url, data) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: data
            }).then(response => {
                const res = response.data
                if(res.code==200) {
                    resolve(res.data)
                }
                reject(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    post (url, data) {
        return new Promise((resolve, reject) => {
            axios.post(url,
                data
            ).then(response => {
                const res = response.data
                if(res.code==200) {
                    resolve(res.data)
                }
                reject(res)
            }).catch(err => {
                reject(err);
            })
        })
    }
};